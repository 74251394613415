<template>
  <div class="list">
    <div class="item" v-for="(item, index) in list" :key="index">
      <div class="title">
        <div class="left">
          <i></i>
          <p>{{ item.benefitName }}</p>
        </div>
        <div class="right">
          <p>{{ item.insuredAmountShow }}</p>
        </div>
      </div>
      <div class="detail" v-if="item.benefitDetails">
        <div class="msg">
          <p>{{ item.benefitDetails }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { productBenefitDetail } from "../utils/api";
export default {
  components: {},
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.productBenefitDetail(this.$route.query.plansId);
  },
  methods: {
    //权益详情
    productBenefitDetail(plansId) {
      productBenefitDetail({
        plansId,
      }).then(({ success, result }) => {
        if (success) {
          this.list = [
            ...result,
            {
              benefitName: "累计给付限额",
              insuredAmountShow: this.$route.query.price,
            },
          ];
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  padding-bottom: 50px;
}
.item {
  padding-top: 13px;
  padding-bottom: 7px;
}
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
    i {
      width: 4px;
      height: 16px;
      background: $primary-color;
      border-radius: 0px 2px 2px 0px;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
      margin-left: 6px;
    }
  }
  .right {
    padding-right: 10px;
    p {
      font-size: 16px;
      font-weight: 500;
      color: $primary-color;
      line-height: 22px;
    }
  }
}
.price {
  margin: 10px 10px 0;
  padding: 12px 12px 16px;
  border-radius: 4px;
  background: $bg7;
  .tit {
    font-size: 15px;
    font-weight: bold;
    color: #333333;
    line-height: 21px;
  }
  .list {
    margin-top: 10px;
    .name {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
    }
  }
}
.detail {
  margin: 10px 10px 0;
  .msg {
    padding: 12px 12px;
    background: $bg7;
    border-radius: 4px;

    .tit {
      font-size: 15px;
      font-weight: bold;
      color: #333333;
      line-height: 21px;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
      text-align: justify;
    }
  }
}
</style>